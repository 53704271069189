import { baseURL } from './baseUrl';

// PriceLists endpoints

export const apiGetPricelist = async () => {
    const response = await baseURL.get(`/resources/pricelists/Pricelists`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetInternationalsPricelist = async () => {
    const response = await baseURL.get(
        `/resources/pricelists/internationals pricelist`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data;
};

// catalogue endpoints
export const apiFetchCataloguesByCollectionName = async (
    data: {
        collection_name: any;
        item_group_code: any;
        item_group_name: any;
        application_name: any;
    }
) => {
    const response = await baseURL.post(
        `/resources/catalogues/collection`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data;
};

export const apiFetchCataloguesByApplicationName = async (
    authToken: string,
    data: {
        item_group_code: any;
        item_group_name: any;
        application_name: any;
    }
) => {
    const response = await baseURL.post(
        `/resources/catalogues/application`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data;
};

//General Info endpoints
export const apiGetFaq = async () => {
    const response = await baseURL.get(`/resources/general-info/FAQ`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetBrandCI = async () => {
    const response = await baseURL.get(`/resources/general-info/Brand_CI`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const apiGetPartnerProgramDoc = async () => {
    const response = await baseURL.get(
        `/resources/general-info/Partnership_Program_Documents`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data;
};
