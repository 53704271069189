import PageLayout from '../../components/shared/layout/PageLayout';
import TextHeader from '../../components/shared/textHeader/TextHeader';
import ResourcesDownloadItems from './components/ResourcesDownloadItems';
import { Box, Container } from '@mui/material';
import { useQuery } from 'react-query';
import {
    apiGetBrandCI,
    apiGetFaq,
    apiGetPartnerProgramDoc,
} from '../../api/apiResources';
import MissingPage from '../../components/shared/errorMessages/MissingPage';
import LoadingScreen from '../../components/shared/loadingScreen/LoadingScreen';
import MissingContent from '../../components/shared/errorMessages/MissingContent';
import { useEffect } from 'react';
function ResourcesPricelists() {
    const {
        isSuccess,
        isError,
        isLoading,
        data: faqData,
    } = useQuery<any, Error>(['faq'], () => apiGetFaq());

    const { data: brandData } = useQuery<any, Error>(['brand-ci'], () =>
        apiGetBrandCI()
    );

    const { data: partnerData } = useQuery<any, Error>(
        ['partner-program-doc'],
        () => apiGetPartnerProgramDoc()
    );
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <PageLayout>
            <>
                {isLoading && <LoadingScreen />}
                {isError && <MissingPage />}

                {isSuccess && (
                    <Container maxWidth='xl'>
                        {faqData?.length !== 0 ? (
                            <>
                                <TextHeader
                                    heading={'Frequently Asked Questions'}
                                    isCentered={false}
                                    width='100%'
                                    marginTop={10}
                                    marginBottom={0}
                                />
                                <Box>
                                    <ResourcesDownloadItems data={faqData} />
                                </Box>
                            </>
                        ) : (
                            <MissingContent sectionName='Frequently Asked Questions' />
                        )}
                        {brandData?.length !== 0 ? (
                            <>
                                <TextHeader
                                    heading={'Brand and C.I'}
                                    isCentered={false}
                                    width='100%'
                                    marginTop={0}
                                    marginBottom={0}
                                />
                                <Box>
                                    <ResourcesDownloadItems data={brandData} />
                                </Box>
                            </>
                        ) : (
                            <MissingContent sectionName='Brand and C.I' />
                        )}

                        {partnerData?.length !== 0 ? (
                            <>
                                <TextHeader
                                    heading={'Partnership Program Documents'}
                                    isCentered={false}
                                    width='100%'
                                    marginTop={0}
                                    marginBottom={0}
                                />
                                <Box>
                                    <ResourcesDownloadItems
                                        data={partnerData}
                                    />
                                </Box>
                            </>
                        ) : (
                            <MissingContent sectionName='Partnership Program Documents' />
                        )}
                    </Container>
                )}
            </>
        </PageLayout>
    );
}
export default ResourcesPricelists;
