import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@emotion/react';
import { ThemeStyles } from '../styles/Theme/ThemeStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ShoppingCartProvider } from '../context/ShoppingCartContext';
import { AuthProvider } from '../context/AuthContext';

import HomePage from '../pages/HomePage/HomePage';
import BrandsTemplate from '../components/shared/pageTemplates/BrandsTemplate';
import CategoryTemplate from '../components/shared/pageTemplates/CategoryTemplate';
import ApplicationsTemplate from '../components/shared/pageTemplates/ApplicationsTemplate';
import ApplicationPage from '../components/shared/pageTemplates/fabric/ApplicationPage';
import CollectionTemplate from '../components/shared/pageTemplates/CollectionTemplate';
import BrandsPage from '../pages/Brands/BrandsPage';
import ProductPage from '../components/shared/pageTemplates/ProductTemplate/ProductPage';
import CheckoutPage from '../pages/CheckoutPage/CheckoutPage';
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import InternationalsPage from '../pages/InternationalsPage/InternationalsPage';
import InternationalsBrandPage from '../pages/InternationalsBrandPage/InternationalsBrandPage';
import AdminPortalDashboardPage from '../pages/AdminPortal/DashboardPage/DashboardPage';
import AdminPortalOpenOrdersPage from '../pages/AdminPortal/OpenOrdersPage/OpenOrdersPage';
import AdminPortalClosedOrdersPage from '../pages/AdminPortal/ClosedOrdersPage/ClosedOrdersPage';
import AdminPortalSettingsPage from '../pages/AdminPortal/SettingsPage/SettingsPage';
import AdminPortalSupportPage from '../pages/AdminPortal/SupportPage/SupportPage';
import AboutUsPage from '../pages/Company/AboutUsPage';
import ResponsibilityPage from '../pages/Company/ResponsibiltyPage';
import DirectorsPage from '../pages/Company/DirectorsPage';
import CareerPage from '../pages/Company/CareerPage';
import StoreLocatorPage from '../pages/Company/StoreLocatorPage';
import PrivateRoutes from '../utilities/privateRoute/PrivateRoutes';
import PaymentSuccess from '../pages/PaymentResultsPage/PaymentSuccess';
import PaymentFail from '../pages/PaymentResultsPage/PaymentFail';
import PayfastStatus from '../pages/PaymentResultsPage/PayfastStatus';
import PayfastPaymentFail from '../pages/PaymentResultsPage/PayfastPaymentFail';
// import OutdoorPage from "../pages/Outdoor/OutdoorPage";
import ApplicationTypeTemplate from '../components/shared/pageTemplates/ApplicationTypeTemplate';
import ApplicationTypeCategoryTemplate from '../components/shared/pageTemplates/ApplicationTypeCategoryTemplate';
import WallPaperCalculatorPage from '../pages/WallPaperPage/WallPaperCalculatorPage';
import ResourcesHome from '../pages/Resources/ResourcesHome';
import ResourcesProducts from '../pages/Resources/ResourcesProducts';
import ResourcesPricelists from '../pages/Resources/ResourcesPricelists';
import ResourcesGeneralInfo from '../pages/Resources/ResourcesGeneralInfo';
import ResourcesCollectionTemplate from '../pages/Resources/pageTemplates/ResourcesCollectionTemplate';
import ResourcesDesignTemplate from '../pages/Resources/pageTemplates/ResourcesDesignTemplate';

const queryClient = new QueryClient();

const AppProvider = () => {
    return (
        <ThemeProvider theme={ThemeStyles}>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <AuthProvider>
                        <ShoppingCartProvider>
                            <Routes>
                                <Route
                                    path='/processing'
                                    element={<PayfastStatus />}
                                />
                                <Route
                                    path='/failed'
                                    element={<PaymentFail />}
                                />
                                <Route
                                    path='/order-failed'
                                    element={<PayfastPaymentFail />}
                                />
                                <Route
                                    path='/success'
                                    element={<PaymentSuccess />}
                                />
                                <Route
                                    path='/failed'
                                    element={<PaymentFail />}
                                />
                                <Route path='/' element={<HomePage />} />
                                <Route path='brand' element={<BrandsPage />} />
                                <Route
                                    path='item_group/9001'
                                    element={<ApplicationTypeTemplate />}
                                />
                                <Route
                                    path='item_group/:item_group/brand/:brand'
                                    element={<BrandsTemplate />}
                                />
                                <Route
                                    path='item_group/:item_group'
                                    element={<CategoryTemplate />}
                                />
                                <Route
                                    path='item_group/:item_group/application/:application'
                                    element={<ApplicationsTemplate />}
                                />
                                <Route
                                    path='item_group/:item_group/application_type/:application_type'
                                    element={<ApplicationTypeTemplate />}
                                />
                                <Route
                                    path='item_group/:item_group/application_type/:application_type/category_name/:category_name'
                                    element={
                                        <ApplicationTypeCategoryTemplate />
                                    }
                                />
                                <Route
                                    path='item_group/:item_group/application/:application/collection/:collection'
                                    element={<CollectionTemplate />}
                                    // eslint-disable-next-line react/jsx-no-comment-textnodes
                                />
                                // ! Users can access the product page via
                                design or design and colour
                                <Route
                                    path='item_group/:item_group/application/:application/collection/:collection/design/:design'
                                    element={<ProductPage />}
                                />
                                <Route
                                    path='item_group/:item_group/application/:application/collection/:collection/design/:design/colour/:colour'
                                    element={<ProductPage />}
                                />
                                <Route
                                    path='wallpaper-calculator'
                                    element={<WallPaperCalculatorPage />}
                                />
                                <Route
                                    path='about-us'
                                    element={<AboutUsPage />}
                                />
                                <Route
                                    path='responsibilities'
                                    element={<ResponsibilityPage />}
                                />
                                <Route
                                    path='directors'
                                    element={<DirectorsPage />}
                                />
                                <Route
                                    path='careers'
                                    element={<CareerPage />}
                                />
                                <Route
                                    path='store-locator'
                                    element={<StoreLocatorPage />}
                                />
                                <Route
                                    path='/:main_category/:application_name'
                                    element={<ApplicationPage />}
                                />
                                <Route
                                    path='/checkout'
                                    element={<CheckoutPage />}
                                />
                                {/* <Route path='/login' element={<LoginPage />} /> */}
                                {/* <Route
                                    path='/register'
                                    element={<RegistrationPage />}
                                /> */}
                                <Route
                                    path='/forgot-password'
                                    element={<ForgotPasswordPage />}
                                />
                                <Route
                                    path='/internationals'
                                    element={<InternationalsPage />}
                                />
                                <Route
                                    path='/internationals-brand/:brandname'
                                    element={<InternationalsBrandPage />}
                                />
                                {/* Private Routes */}
                                <Route element={<PrivateRoutes />}>
                                    <Route
                                        path='/admin-portal-dashboard'
                                        element={<AdminPortalDashboardPage />}
                                    />
                                    <Route
                                        path='/admin-portal-open-orders'
                                        element={<AdminPortalOpenOrdersPage />}
                                    />
                                    <Route
                                        path='/admin-portal-closed-orders'
                                        element={
                                            <AdminPortalClosedOrdersPage />
                                        }
                                    />

                                    <Route
                                        path='/admin-portal-settings'
                                        element={<AdminPortalSettingsPage />}
                                    />
                                    <Route
                                        path='/admin-portal-support'
                                        element={<AdminPortalSupportPage />}
                                    />
                                    <Route
                                        path='/resources'
                                        element={<ResourcesHome />}
                                    />
                                    <Route
                                        path='/resources/products'
                                        element={<ResourcesProducts />}
                                    />
                                    <Route
                                        path='/resources/pricelists'
                                        element={<ResourcesPricelists />}
                                    />
                                    <Route
                                        path='/resources/general-info'
                                        element={<ResourcesGeneralInfo />}
                                    />
                                    <Route
                                        path='/resources/item_group_name/:item_group_name/item_group/:item_group/application/:application/collection/:collection'
                                        element={
                                            <ResourcesCollectionTemplate />
                                        }
                                    />
                                    <Route
                                        path='/resources/item_group/:item_group/application/:application/collection/:collection/design/:design/colour/:colour'
                                        element={<ResourcesDesignTemplate />}
                                    />
                                </Route>
                                {/* End Private Routes */}
                            </Routes>
                        </ShoppingCartProvider>
                    </AuthProvider>
                </Router>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default AppProvider;
