import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ResourcesProductImg from '../../../assets/resources/Collection Squares 1200 x 1200.jpg';
import ResourcesPricelistImg from '../../../assets/resources/Cowpoke_Cobblestone_2_Resized.jpg';
import ResourcesGeneralImg from '../../../assets/faq-page/careershero.jpg';

function ResourcesMenuGrid() {
    const navigate = useNavigate();
    return (
        <Container maxWidth='xl'>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pb: '50px',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: { xs: '100%', sm: '60vh' },
                        gap: '10px',
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {/* Products box */}

                    <Box
                        sx={{
                            position: 'relative',
                            flex: '6',
                            cursor: 'pointer',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            '&:hover img': {
                                transform: 'scale(1.03)',
                            },
                        }}
                        onClick={() => {
                            navigate('/resources/products');
                        }}
                    >
                        <img
                            src={ResourcesProductImg}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                transition: 'transform 0.5s ease',
                                background:
                                    'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                            }}
                            alt='categories'
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'end',
                                padding: '0 0 20px 30px',
                                gap: '20px',
                                background:
                                    'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                            }}
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    textAlign: 'start',
                                    fontWeight: 'lighter',
                                    color: '#fff',
                                    fontSize: '35px',
                                    textTransform: 'capitalize',
                                    lineHeight: '0',
                                }}
                            >
                                Discover Products
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'start',
                                    fontWeight: 'lighter',
                                    color: '#fff',
                                    fontSize: '16px',
                                }}
                            >
                                Access to detailed product information,
                                catalogues, and images.
                            </Typography>
                        </Box>
                    </Box>

                    {/* Right column */}

                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '6',
                            gap: '10px',
                        }}
                    >
                        {/* pricelists box */}

                        <Box
                            sx={{
                                position: 'relative',
                                flex: '6',
                                cursor: 'pointer',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                '&:hover img': {
                                    transform: 'scale(1.03)',
                                },
                            }}
                            onClick={() => {
                                navigate('/resources/pricelists');
                            }}
                        >
                            <img
                                src={ResourcesPricelistImg}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transition: 'transform 0.5s ease',
                                    background:
                                        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                                }}
                                alt='categories'
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'end',
                                    padding: '0 0 20px 30px',
                                    gap: '20px',
                                    background:
                                        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                                }}
                            >
                                <Typography
                                    variant='h6'
                                    sx={{
                                        textAlign: 'start',
                                        fontWeight: 'lighter',
                                        color: '#fff',
                                        fontSize: '35px',
                                        textTransform: 'capitalize',
                                        lineHeight: '0',
                                    }}
                                >
                                    Pricelists
                                </Typography>
                                <Typography
                                    sx={{
                                        textAlign: 'start',
                                        fontWeight: 'lighter',
                                        color: '#fff',
                                        fontSize: '16px',
                                    }}
                                >
                                    Download all the latest Hertex and HAUS
                                    price lists.
                                </Typography>
                            </Box>
                        </Box>

                        {/* General info box */}
                        <Box
                            sx={{
                                position: 'relative',
                                flex: '6',
                                cursor: 'pointer',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                '&:hover img': {
                                    transform: 'scale(1.03)',
                                },
                            }}
                            onClick={() => {
                                navigate('/resources/general-info');
                            }}
                        >
                            <img
                                src={ResourcesGeneralImg}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transition: 'transform 0.5s ease',
                                    background:
                                        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                                }}
                                alt='categories'
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'end',
                                    padding: '0 0 20px 30px',
                                    gap: '20px',
                                    background:
                                        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                                }}
                            >
                                <Typography
                                    variant='h6'
                                    sx={{
                                        textAlign: 'start',
                                        fontWeight: 'lighter',
                                        color: '#fff',
                                        fontSize: '35px',
                                        textTransform: 'capitalize',
                                        lineHeight: '0',
                                    }}
                                >
                                    General Info
                                </Typography>
                                <Typography
                                    sx={{
                                        textAlign: 'start',
                                        fontWeight: 'lighter',
                                        color: '#fff',
                                        fontSize: '16px',
                                    }}
                                >
                                    Brand, Frequently Asked Questions, and other
                                    general downloadables.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default ResourcesMenuGrid;
