import PageLayout from '../../components/shared/layout/PageLayout';
import TextHeader from '../../components/shared/textHeader/TextHeader';
import ResourcesDownloadItems from './components/ResourcesDownloadItems';
import { Box, Container } from '@mui/material';
import { useQuery } from 'react-query';
import {
    apiGetInternationalsPricelist,
    apiGetPricelist,
} from '../../api/apiResources';
import MissingPage from '../../components/shared/errorMessages/MissingPage';
import LoadingScreen from '../../components/shared/loadingScreen/LoadingScreen';
import MissingContent from '../../components/shared/errorMessages/MissingContent';
import { useEffect } from 'react';
function ResourcesPricelists() {
    const {
        isSuccess,
        isError,
        isLoading,
        data: pricelistData,
    } = useQuery<any, Error>(['pricelist'], () => apiGetPricelist());
    const { data: interPricelistData } = useQuery<any, Error>(
        ['international pricelist'],
        () => apiGetInternationalsPricelist()
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <PageLayout>
            <>
                {isLoading && <LoadingScreen />}
                {isError && <MissingPage />}
                {isSuccess && (
                    <Container maxWidth='xl'>
                        {pricelistData?.length !== 0 ? (
                            <>
                                <TextHeader
                                    heading={'Pricelists'}
                                    isCentered={false}
                                    width='100%'
                                    marginTop={10}
                                    marginBottom={0}
                                />
                                <Box>
                                    <ResourcesDownloadItems
                                        data={pricelistData}
                                    />
                                </Box>
                            </>
                        ) : (
                            <MissingContent sectionName='Pricelists' />
                        )}
                        {interPricelistData?.length !== 0 ? (
                            <>
                                <TextHeader
                                    heading={'International Pricelists'}
                                    isCentered={false}
                                    width='100%'
                                    marginTop={0}
                                    marginBottom={0}
                                />
                                <Box>
                                    <ResourcesDownloadItems
                                        data={interPricelistData}
                                    />
                                </Box>
                            </>
                        ) : (
                            <MissingContent sectionName='International Pricelists' />
                        )}
                    </Container>
                )}
            </>
        </PageLayout>
    );
}
export default ResourcesPricelists;
